<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto">
          <h1>Team</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Team Members"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            v-model="searchTerm"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="blue white--text"
                  class="mr-2"
                  :to="{ name: 'module-team-resourcing' }"
                  v-on="on"
                >
                  <v-icon>mdi-table-account</v-icon>
                </v-btn>
              </template>
              <span>Resourcing</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="addMemberForm.dialog = true"
                  :disabled="tableLoading"
                  v-on="on"
                >
                  <v-icon>mdi-account-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Team Member</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  class="mr-2"
                  :to="{ name: 'module-team-organisation' }"
                  v-on="on"
                >
                  <v-icon>mdi-sitemap</v-icon>
                </v-btn>
              </template>
              <span>View Organisation Structure</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="warning"
                  v-on="on"
                  :to="{ name: 'module-team-roles-permissions' }"
                >
                  <v-icon>mdi-key</v-icon>
                </v-btn>
              </template>
              <span>Roles & Permissions</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="member in teamMembers" v-bind:key="member.id" cols="4">
          <v-card :loading="memberLoading === member.id">
            <v-list-item
              :to="{
                name: 'module-team-profile',
                params: { teamMemberId: member.id },
              }"
            >
              <v-badge
                :color="member.user ? 'success' : 'error'"
                icon="mdi-account"
                overlap
                left
                bordered
              >
                <v-list-item-avatar
                  :color="
                    member.user
                      ? member.user.status === 1
                        ? 'accent'
                        : 'grey'
                      : 'accent'
                  "
                  class="ml-0 mt-0 mb-0"
                >
                  <img
                    v-if="member.user && member.user.avatar"
                    :src="getAvatar(member.user.avatar)"
                    :alt="member.full_name"
                  />
                  <span v-else class="white--text">{{ member.initials }}</span>
                </v-list-item-avatar>
              </v-badge>
              <v-list-item-content>
                <v-list-item-title>{{ member.full_name }}</v-list-item-title>
                <v-list-item-subtitle class="text--primary">{{
                  member.email
                }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{
                  member.postings.length ? member.postings[0].post.name : "-"
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>{{
                  member.team_member_type.description
                }}</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-card-actions>
              <v-menu offset-y>
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        x-small
                        depressed
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                      >
                        <v-icon small>mdi-account-cog</v-icon>
                        <v-icon small>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <span>User Actions</span>
                  </v-tooltip>
                </template>
                <v-list dense>
                  <v-list-item
                    :to="{
                      name: 'module-team-profile',
                      params: { teamMemberId: member.id },
                    }"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>View Profile</v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="member.user && member.user.status === 0"
                    @click="resendSetupEmail(member.user.id, member.id)"
                  >
                    <v-list-item-icon>
                      <v-icon color="warning">mdi-email-send</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content
                      >Resend Setup Email</v-list-item-content
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
              <v-menu offset-y v-if="member.user">
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    :color="getUserStatusColor(member.user)"
                    v-on="on"
                  >
                    {{ getUserStatusLabel(member.user) }}
                    <v-icon x-small right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    key="active"
                    :disabled="member.user.apps[0].pivot.status === 1"
                    @click="updateUserStatus(member, 1)"
                  >
                    <v-list-item-title>Active</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    key="archived"
                    :disabled="member.user.apps[0].pivot.status === 2"
                    @click="updateUserStatus(member, 2)"
                  >
                    <v-list-item-title>Archived</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    key="suspended"
                    :disabled="member.user.apps[0].pivot.status === 3"
                    @click="updateUserStatus(member, 3)"
                  >
                    <v-list-item-title>Suspended</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="addMemberForm.dialog" persistent max-width="600px">
      <v-card>
        <v-form @submit.prevent="addTeamMember" method="post">
          <v-card-title>
            <span class="headline">Add Team Member</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-alert type="error" v-if="addMemberForm.error">
              Sorry, something went wrong when attempting to create this user.
              If it continues to fail, please contact Vital Hike for help
            </v-alert>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="First Name *"
                  type="text"
                  v-model="addMemberForm.firstName"
                  required
                  outlined
                  :hide-details="
                    !addMemberForm.errors.hasOwnProperty('firstName')
                  "
                  :error="addMemberForm.errors.hasOwnProperty('firstName')"
                  :error-messages="addMemberForm.errors['firstName']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Last Name *"
                  type="text"
                  v-model="addMemberForm.lastName"
                  required
                  outlined
                  :hide-details="
                    !addMemberForm.errors.hasOwnProperty('lastName')
                  "
                  :error="addMemberForm.errors.hasOwnProperty('lastName')"
                  :error-messages="addMemberForm.errors['lastName']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-model="addMemberForm.createUser"
                  label="Do you want to create a user?"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="roles"
                  item-text="name"
                  item-value="id"
                  label="User Role(s) *"
                  v-model="addMemberForm.roles"
                  multiple
                  outlined
                  :hide-details="!addMemberForm.errors.hasOwnProperty('roles')"
                  v-if="addMemberForm.createUser"
                  :error="addMemberForm.errors.hasOwnProperty('roles')"
                  :error-messages="addMemberForm.errors['roles']"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Email Address *"
                  type="email"
                  v-model="addMemberForm.emailAddress"
                  outlined
                  :hide-details="!addMemberForm.errors.hasOwnProperty('email')"
                  :error="addMemberForm.errors.hasOwnProperty('email')"
                  :error-messages="addMemberForm.errors['email']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="teamMemberTypes"
                  item-text="description"
                  item-value="id"
                  label="Member Type *"
                  v-model="addMemberForm.memberType"
                  outlined
                  :hide-details="
                    !addMemberForm.errors.hasOwnProperty('memberType')
                  "
                  :error="addMemberForm.errors.hasOwnProperty('memberType')"
                  :error-messages="addMemberForm.errors['memberType']"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <header>Status</header>
                <v-checkbox
                  label="Archived"
                  v-model="addMemberForm.archived"
                ></v-checkbox>
              </v-col>
              <v-col cols="6">
                <header>Access</header>
                <v-radio-group v-model="addMemberForm.access">
                  <v-radio label="Active" value="active"></v-radio>
                  <v-radio label="Suspended" value="suspended"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="addMemberForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="addMemberForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <UpdateUserStatusWarning ref="updateUserStatusWarning" />
  </div>
</template>

<script>
import UpdateUserStatusWarning from "./components/UpdateUserStatusWarning";

export default {
  components: {
    UpdateUserStatusWarning,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Team",
          disabled: true,
        },
      ],
      searchTerm: null,
      appId: this.$route.params.id,
      tableLoading: false,
      tableHeaders: [
        { text: "Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Type", value: "member_type" },
        { text: "Actions", value: "actions" },
      ],
      addMemberForm: {
        dialog: false,
        error: false,
        errors: {},
        firstName: null,
        lastName: null,
        emailAddress: null,
        memberType: null,
        archived: false,
        access: "active",
        createUser: true,
        roles: null,
        loading: false,
      },
      userStatusUpdating: null,
      userStatuses: [
        { value: 1, label: "Active", color: "success" },
        { value: 2, label: "Archived", color: "error" },
        { value: 3, label: "Suspended", color: "error" },
        { value: 4, label: "Active - Setup Required", color: "warning" },
      ],
      memberLoading: null,
    };
  },

  computed: {
    teamMembers() {
      let teamMembers = this.$store.state.team["teamMembers"];

      if (
        (this.searchTerm !== "" || this.searchTerm !== null) &&
        typeof this.searchTerm === "string"
      ) {
        teamMembers = teamMembers.filter(m => {
          const name = m.full_name.toLowerCase();
          const email = m.email.toLowerCase();
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            email.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return teamMembers;
    },
    teamMemberTypes() {
      return this.$store.state.team["teamMemberTypes"];
    },
    roles() {
      return this.$store.state.team["appRoles"];
    },
  },

  methods: {
    addTeamMember: function() {
      let payload = {};
      payload.appId = this.$route.params.id;
      payload.firstName = this.addMemberForm.firstName;
      payload.lastName = this.addMemberForm.lastName;
      payload.email = this.addMemberForm.emailAddress;
      payload.memberType = this.addMemberForm.memberType;
      payload.memberStatus =
        this.addMemberForm.access === "active"
          ? 1
          : this.addMemberForm.archived
          ? 2
          : 3;
      payload.createUser = this.addMemberForm.createUser;
      if (payload.createUser === true) {
        payload.roles = this.addMemberForm.roles;
      }

      this.addMemberForm.loading = true;

      this.$store
        .dispatch("team/addMember", {
          ...payload,
        })
        .then(() => {
          this.addMemberForm = {
            dialog: false,
            error: false,
            errors: {},
            firstName: null,
            lastName: null,
            emailAddress: null,
            memberType: null,
            archived: false,
            access: "active",
            createUser: false,
            roles: null,
            loading: false,
          };
        })
        .catch(err => {
          this.addMemberForm.error = true;
          this.addMemberForm.errors = err.response.data.errors;
          this.addMemberForm.loading = false;
        });
    },
    updateUserStatus: function(teamMember, statusId) {
      this.$refs.updateUserStatusWarning.updateUserStatus(teamMember, statusId);
    },
    getUserStatusColor: function(user) {
      const statusId =
        user.apps[0].pivot.status === 1
          ? user.status === 1
            ? 1
            : 4
          : user.apps[0].pivot.status;
      const status = this.userStatuses.find(s => s.value === statusId);

      return status.color;
    },
    getUserStatusLabel: function(user) {
      const statusId =
        user.apps[0].pivot.status === 1
          ? user.status === 1
            ? 1
            : 4
          : user.apps[0].pivot.status;
      const status = this.userStatuses.find(s => s.value === statusId);

      return status.label;
    },
    getAvatar: function(avatarUrl) {
      return `${process.env.VUE_APP_ACCOUNTS_URL}/storage/${avatarUrl}`;
    },
    resendSetupEmail: function(userId, memberId) {
      const appId = this.$route.params.id;
      this.memberLoading = memberId;

      this.$store
        .dispatch("users/resentSetupEmail", { appId, userId })
        .then(() => {
          this.memberLoading = null;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Setup Email Successfully Resent",
          });
        })
        .catch(() => {
          this.memberLoading = null;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Error Occurred when Attempting to Resent Setup Email",
          });
        });
    },
  },
};
</script>
